import Icon1 from '../../ProjectsImgs/icons_imgs/gplay-icon.png'
import Icon2 from '../../ProjectsImgs/icons_imgs/xml_icon.png'

import FrontImg from '../../ProjectsImgs/AndroidImgs/SrcAusfallApp/FrontImg.jpg'

export const AusfallAppTitle = 'Ausfall-App - Android Version'
export const AusfallAppFrontImg = FrontImg
export const AusfallAppDescription = 'Ein Programm zur Meldung von Störungen an Fahrzeugen. Android Vers.'
export const AusfallAppTechDescription = 'Android Studio - Kotlin, XML, Firebase Database'
export const AusfallAppGitLink = 'https://github.com/ollo7776/RepairAuth1_0'
export const AusfallAppPlayLink = "https://play.google.com/store/apps/details?id=com.ollo.repairauth1_0&gl=DE"

const iconsList = [
    { 
        id: 1,
        icon: Icon1
    },
    {
        id: 2,
        icon: Icon2
    },    
]

export const AusfallAppIconsComponent=()=> {
    return (
        <div className='icons-on-card'>
            {iconsList.map((icon) => (
                <img className="project-icon" key={icon.id} src={icon.icon} alt={icon.id} />
            ))}
        </div>
    );
}
