import Img1 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_1.jpg'
import Img2 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_1.jpg'
import Img3 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_3.jpg'
import Img4 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_4.jpg'
import Img5 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_5.jpg'
import Img6 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_6.jpg'
import Img7 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_7.jpg'
import Img8 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_8.jpg'
import Img9 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_9.jpg'
import Img10 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_10.jpg'
import Img11 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_11.jpg'
import Img12 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_12.jpg'
import Img13 from '../../ProjectsImgs/SrcXamlCsTiU/src_img_13.jpg'

import Icon1 from '../../ProjectsImgs/icons_imgs/cs_icon.png'
import Icon2 from '../../ProjectsImgs/icons_imgs/xaml_icon.png'
import Icon3 from '../../ProjectsImgs/icons_imgs/vs2022_icon.png'

import FrontImg from '../../ProjectsImgs/SrcXamlCsTiU/src_img_1.jpg'

export const XamlImagesList = [
    {
        id: 1,
        image:
            Img1
    },
    {
        id: 2,
        image:
            Img2
    },
    {
        id: 3,
        image:
            Img3
    },
    {
        id: 4,
        image:
            Img4
    },
    {
        id: 5,
        image:
            Img5
    },
    {
        id: 6,
        image:
            Img6
    },
    {
        id: 7,
        image:
            Img7
    },
    {
        id: 8,
        image:
            Img8
    },
    {
        id: 9,
        image:
            Img9
    },
    {
        id: 10,
        image:
            Img10
    },
    {
        id: 11,
        image:
            Img11
    },
    {
        id: 12,
        image:
            Img12
    },
    {
        id: 13,
        image:
            Img13
    },
]

export const XamlTitle = 'Fleet Management Xaml C++ vers. Desktop'
export const XamlFrontImg = FrontImg
export const XamlDescription = 'Design einer Desktop-Anwendung für das Flottenmanagement.'
export const XamlTechDescription = 'Tech.: XAML-Sprache von Windows Presentation Foundation, C#, Visual Studio 2022'

const iconsList = [
    {
        id: 1,
        icon: Icon1
    },
    {
        id: 2,
        icon: Icon2
    },
    {
        id: 3,
        icon: Icon3
    },      
]

export const XamlIconsComponent=()=> {
    return (
        <div className='icons-on-card'>
            {iconsList.map((icon) => (
                <img className="project-icon" key={icon.id} src={icon.icon} alt={icon.id} />
            ))}
        </div>
    );
}
