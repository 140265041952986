import Icon1 from '../../ProjectsImgs/icons_imgs/js_icon.png'
import Icon2 from '../../ProjectsImgs/icons_imgs/html5_icon.png'
import Icon3 from '../../ProjectsImgs/icons_imgs/css3_icon.png'

import FrontImg from '../../ProjectsImgs/SrcPinkGonk/PimkGong.png'

export const PimkGonkTitle = 'PimkGonk - Spiel'
export const PimkGonkFrontImg = FrontImg
export const PimkGonkDescription = 'Um zu gewinnen, müssen Sie nur drei Punkte erzielen! Dieser Ball folgt nicht immer den Regeln der Physik'
export const PimkGonkTechDescription = 'JS, HTML, CSS'
export const PimkGonkPlayLink = "/spielchen"

const iconsList = [
    { 
        id: 1,
        icon: Icon1
    },
    {
        id: 2,
        icon: Icon2
    },  
    {
        id: 3,
        icon: Icon3
    },    
]

export const PimkGonkIconsComponent=()=> {
    return (
        <div className='icons-on-card'>
            {iconsList.map((icon) => (
                <img className="project-icon" key={icon.id} src={icon.icon} alt={icon.id} />
            ))}
        </div>
    );
}
