import React from 'react'
import { motion } from 'framer-motion'
// import { SliderConvertDesktop } from './sliders/SliderConvertDesktop'

export const PopUpSlides = ({ open, onClose, Slider, imgsArr }) => {
    if (!open) return null   

    return (
        // <div className='overlay-popup'>
            <div className='modal-container-popup'>
                {/* SLIDER IMGS */}
                <Slider imgsArr={imgsArr} />
                {/* <SliderConvertDesktop /> 
                                        */}
                {/* END SLIDER IMGS Popup colse button */}
                <div className='modal-container-popup-btn'>
                    <div onClick={onClose} className='popup-close-btn'>
                        <svg
                            viewBox="-2.5 -3 15 15"
                            width='40px' height='40px'>
                            <motion.path
                                d="M 5 5.4853 L 1.4645 9.0208 L 0.7574 8.3137 L 4.2929 4.7782 L 0.7574 1.2426 L 1.4645 0.5355 L 5 4.0711 L 8.5355 0.5355 L 9.2426 1.2426 L 5.7071 4.7782 L 9.2426 8.3137 L 8.5355 9.0208 L 5 5.4853"
                                fill="transparent"
                                strokeWidth="0.1"
                                stroke="black"
                                strokeLinecap="round"
                                animate={{ pathLength: [0, 1] }}
                                transition={{ delay: 0, duration: 4 }}
                            />
                        </svg>
                    </div>
                </div>
            </div>
        // </div>
    )
}

export default PopUpSlides