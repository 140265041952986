import React, { useState } from "react";
import { motion } from "framer-motion";
import { Slider } from './sliders/Slider'
import PopUpSlides from "./popups/PopUpSlides"
import PopupVideo from "./popups/PopupVideo"

export function CardOnProjects({ title, FrontImg, imgsArr, IconsArr, description, techDescription, videoAddress }) {
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupV, setOpenPopupV] = useState(false)

    return (
        <>
            <div className="project-card-title"> {title} </div>
            <PopupVideo open={openPopupV} onClose={() => setOpenPopupV(false)} videoAddress={videoAddress} />
            <PopUpSlides open={openPopup} onClose={() => setOpenPopup(false)} Slider={Slider} imgsArr={imgsArr} />
            <div className="project-card-in" >
                <div className="images-on-card" onClick={() => setOpenPopup(true)}>
                    <div className="img-container">
                        {/* <div className="project-img" style={{ backgroundImage: `url(${FrontImg})` }}></div> */}
                        <img className="project-img" src={FrontImg} alt="img" />
                        {/* <FrontImg /> */}
                    </div>
                    <div className="icons-on-card">
                        <IconsArr />
                    </div>
                </div>
                <div className="project-card-right">
                    <div className="project-card-text">
                        {description}<br />
                        <span>{techDescription}</span><br />
                    </div>


                    <div className="project-card-btns" >
                        {/* BUTTON TO OPEN POPUP VIDEO PLAYER */}
                        <button>
                            <svg onClick={() => setOpenPopupV(true)}
                                viewBox="0 -2 15 15"
                                width='70px' height='50px'>
                                <motion.path
                                    d="M 2 1 L 14 1 L 15 2 L 15 10 L 14 11 L 2 11 L 1 10 L 1 2 L 2 1 M 6 3 L 6 9 L 11 6 L 6 3"
                                    fill="transparent"
                                    strokeWidth="1"
                                    stroke="gray"
                                    strokeLinecap="round"
                                    animate={{ pathLength: [0, 1] }}
                                    transition={{ delay: 0, duration: 4 }}
                                />
                            </svg>
                        </button>
                        <button>
                            {/* BUTTON TO OPEN POPUP SLIDER */}
                            <svg onClick={() => setOpenPopup(true)}
                                viewBox="-2 -3 15 15"
                                width='50px' height='50px'>
                                <motion.path
                                    d="M 3 2 L 9 2 L 9 8 L 3 8 L 3 2 M 1 6 L 1 10 L 5 10 L 1 10 M 11 4 L 11 0 L 7 0 L 11 0"
                                    fill="transparent"
                                    strokeWidth="1"
                                    stroke="gray"
                                    strokeLinecap="round"
                                    animate={{ pathLength: [0, 1] }}
                                    transition={{ delay: 0, duration: 4 }}
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div >
        </>
    );
}



