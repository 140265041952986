import JqueryMobile1 from '../../ProjectsImgs/SrcJqueryMobile/src_jquery_mobile_1.jpg'
import JqueryMobile2 from '../../ProjectsImgs/SrcJqueryMobile/src_jquery_mobile_2.jpg'
import JqueryMobile3 from '../../ProjectsImgs/SrcJqueryMobile/src_jquery_mobile_3.jpg'
import JqueryMobile4 from '../../ProjectsImgs/SrcJqueryMobile/src_jquery_mobile_4.jpg'
import JqueryMobile5 from '../../ProjectsImgs/SrcJqueryMobile/src_jquery_mobile_5.jpg'
import JqueryMobile6 from '../../ProjectsImgs/SrcJqueryMobile/src_jquery_mobile_6.jpg'

import JqueryIcon1 from '../../ProjectsImgs/icons_imgs/css3_icon.png'
import JqueryIcon2 from '../../ProjectsImgs/icons_imgs/html5_icon.png'
import JqueryIcon3 from '../../ProjectsImgs/icons_imgs/jquery_icon.png'
import JqueryIcon4 from '../../ProjectsImgs/icons_imgs/js_icon.png'
import JqueryIcon5 from '../../ProjectsImgs/icons_imgs/svg_icon.png'
import JqueryIcon6 from '../../ProjectsImgs/icons_imgs/ubuntu_icon.png'

import FrontImg from '../../ProjectsImgs/SrcJqueryMobile/src_jquery_mobile_1.jpg'

export const JqMimagesList = [
    {
       id: 1,
        image:
        JqueryMobile1
    },
    {
        id: 2,
        image:
        JqueryMobile2
    },
    {
        id: 3,
        image:
        JqueryMobile3
    },
    {
        id: 4,
        image:
        JqueryMobile4
    },
    {
        id: 5,
        image:
        JqueryMobile5
    },
    {
        id: 6,
        image:
        JqueryMobile6
    },
]

export const JqMTitle = 'JQuery vers. Mobile'
export const JqMFrontImg = FrontImg
export const JqMDescription = 'Ein Programm, das die Möglichkeiten der JQuery-Bibliothek in Kombination mit CSS Animate präsentiert.'
export const JqMTechDescription = 'FRONTEND: REACTHOOKS JS, VERSION FÜR DESKTOP GERÄTE'

const iconsList = [
    {
        id: 1,
        icon: JqueryIcon1
    },
    {
        id: 2,
        icon: JqueryIcon2
    },
    {
        id: 3,
        icon: JqueryIcon3
    },
    {
        id: 4,
        icon: JqueryIcon4
    },
    {
        id: 5,
        icon: JqueryIcon5
    },
    {
        id: 6,
        icon: JqueryIcon6
    },
]

export const JqMIconsComponent=()=> {
    return (       
        <div className='icons-on-card'>
            {iconsList.map((icon) => (
                <img className="project-icon" key={icon.id} src={icon.icon} alt={icon.id} />
            ))}
        </div>
    );
}
