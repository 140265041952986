// Animation of letters on the main page. Framer Motion animations
import React from 'react'
import { motion, useAnimation, useCycle } from 'framer-motion'

export const LettersAnim = () => {
    const controls = useAnimation()
    const loaderVariants = {
        animationOne: {
            x: [-20, 20],
            y: [0, -30],
            transition: {
                x: {
                    yoyo: 20,
                    duration: 0.5,
                },
                y: {
                    yoyo: 40,
                    duration: 0.25,
                    ease: 'easeOut'
                }
            }
        },
        animationTwo: {
            y: [0, -180],
            x: 0,
            transition: {
                y: {
                    yoyo: 20,
                    duration: 0.25,
                    ease: 'easeOut'
                }
            }
        },
        animationThree: {
            x: [0, 20, 40, 60, 80, 60, 40, 20, 0, -20, -40, -60, -80, -60, -40, -20, 0],
            y: [0, -20, -40, -20, 0, 20, 40, 20, 0, -20, -40, -20, 0, 20, 40, 20, 0],
            transition: {
                y: {
                    loop: 20,
                    duration: 3,
                    ease: 'easeOut'
                },
                x: {
                    loop: 20,
                    duration: 3,
                    ease: 'easeOut'
                }

            }
        },
        animationFour: {
            x: [0, 200, -200, 200, -200, 0],
            y: [0, -200, -200, 200, 200, 0],
            transition: {
                y: {
                    loop: 20,
                    duration: 4,
                    ease: 'easeOut'
                },
                x: {
                    loop: 20,
                    duration: 4,
                    ease: 'easeOut'
                }

            }
        },
        animationFive: {
            rotate: 360,
            transition: {
                yoyo: 20,
                duration: 6
            }
        },
        animationSix: {

        },

    }


    const [animation, cycleAnimation] = useCycle('animationSix', 'animationFive', 'animationOne', 'animationTwo', 'animationThree', 'animationFour')


    controls.start(i => ({
        // opacity: [1, 1],
        rotate: [-360, 0, 360, 15, -15, 15, -15, 15, -15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        //x: 10,
        transition: { delay: i * 5, duration: 25, repeat: Infinity },
    })
    )

    return (
        <>
            <motion.div animate={animation} variants={loaderVariants} >
                {/* <motion.div style={{ display: 'flex' }}  > */}
                <motion.div className='letters-anim-container'  >
                    <motion.div custom={0} animate={controls} className='style-letters-animation' style={{ backgroundColor: "yellow" }}  >L</motion.div>
                    <motion.div custom={1} animate={controls} className='style-letters-animation' style={{ backgroundColor: "magenta" }}>E</motion.div>
                    <motion.div custom={2} animate={controls} className='style-letters-animation' style={{ backgroundColor: "green" }}>T</motion.div>
                    <motion.div custom={3} animate={controls} className='style-letters-animation' style={{ backgroundColor: "red" }}>T</motion.div>
                    <motion.div custom={4} animate={controls} className='style-letters-animation' style={{ backgroundColor: "orange" }}>E</motion.div>
                    <motion.div custom={5} animate={controls} className='style-letters-animation' >R</motion.div>
                    <motion.div custom={6} animate={controls} className='style-letters-animation' style={{ backgroundColor: "lightblue" }}>S</motion.div>
                </motion.div>
            </motion.div>            
            <button onClick={() => cycleAnimation()}>Animation</button>
        </>
    )
}
