// fun fact: `viewBox` has always been camel-cased.
export const CookiesMonster = () => {
    return (
        <svg width={60}
            height={60}
         
        >
            {/* <!-- THe COOKIE MONSTER CRICLE --> */}
            <path d="M 27 26 L 2 26 C 2 -4 57 -9 57 31 C 57 61 17 66 7 51 L 27 26" fill="brown" strokeWidth="1"
                stroke="tomato" strokeLinecap="round" />

            <path d="M 21 10 L 6 22 C 14 26 15 24 21 10 M 9 22 C 11 20.3333 12 19.6667 15 17 C 13 21 14 22 11 23 M 28 11 C 32 11 32 5 28 5 C 24 5 24 11 28 11 M 28 14 C 28 12 25 12 25 14 C 25 16 28 16 28 14 M 42 12 C 42 9 38 9 38 12 C 38 15 42 15 42 12 M 49 15 C 49 13 46 13 46 15 C 46 17 49 17 49 15 M 33 22 C 33 19 28 19 28 22 C 28 25 33 25 33 22 M 45 22 C 45 17 38 17 38 22 C 38 27 45 27 45 22 M 50 31 C 50 29 47 29 47 31 C 47 33 50 33 50 31 M 27 47 C 27 41 19 41 19 47 C 19 52 27 52 27 47 M 43 50 C 43 46 37 46 37 50 C 37 54 43 54 43 50 M 39 36 C 39 32 33 32 33 36 C 33 40 39 40 39 36 M 9 22 M 31 30"
                fill="black"
                strokeWidth="1"
                stroke="transparent" strokeLinecap="round" />
        </svg>
    )
}
export default CookiesMonster