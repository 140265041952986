import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../../styles/style.css'
import { NavBar } from '../../components/menu-bar-up/NavBar'
import url from '../../components/useURL.js'
import MyCode from '../../images/MyCode.png'
import { Clock } from '../SVGicons/Clock'

export const Validate24hours = () => {
    // Variable to store user data
    const [values, setValues] = useState({
        firstCode: "",
    });
    // Variables for user response information button and input action
    const [submitted, setSubmitted] = useState(false);
    const [valid, setValid] = useState(false)
    // answer form server
    const [dataResp, setDataResp] = useState([])

    // set values from input fields into a variable
    const handleFirstCodeInputChange = event => {
        setValues({
            ...values,
            firstCode: event.target.value
        })
    }

    // copying sample data to the form if the user has no idea what to write
    const CopyToInputField = e => {
        e.preventDefault()
        setValues({
            firstCode: "13:03"
        })
    }

    // Method run after user approves the form
    const handleSubmit = (e) => {
        e.preventDefault();
        if (values.firstCode) {
            setValid(true);
        }
        setSubmitted(true);
        console.log(values.firstCode);
    }

    //Header data needed to send data in json format
    let headerOption = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    // Data send to server
    useEffect(() => {
        if (submitted && valid) {
            let dataToSend = `{"time":"${values.firstCode}","type":"com.reactcwqr.codewars.service.Regex24HoursService"}`
            axios.post(url + ':8081/default', dataToSend, headerOption)
                .then(function (response) {
                    setDataResp(response.data + "")
                })
                .catch(function (error) {
                    console.log(error)
                    setDataResp("Daten sind ungültig: " + error)
                })
        }
    });

    // First container item-0 with navbar
    // Second container item-1 with text and form input and buttons
    // Reply from the server with the right colors
    // Submitting button
    // Third item-2 container with text info
    return (
        <>
            <div className="body-container">
                <div className="item-0">
                    <span className='nav-bar'>
                        <NavBar />
                    </span>
                </div>

                <div className="item-1">
                    <span className="card">
                        <article>
                            <h1>Validate24hours</h1>
                            <p>Dieses Programm prüft, ob das eingegebene Zeitformat korrekt ist.<br />
                                Beispielfragment zum Einfügen: 13:03<br />
                                <button className="form-input-btn"
                                    onClick={CopyToInputField}>
                                    In das Formular kopieren
                                </button>
                            </p>
                            <form className="form" onSubmit={handleSubmit}>
                                <input
                                    onChange={handleFirstCodeInputChange}
                                    value={values.firstCode}
                                    className="form-inputs"
                                    placeholder="type the hour to check..."
                                    name="firstCode"
                                />
                                <p style={{ color: 'white' }}>Antwort vom Server:</p>
                                {
                                    submitted && !values.firstCode ?
                                        <p style={{ backgroundColor: 'orange' }}>Es wird kein Code eingefügt</p>
                                        : dataResp === 'false' ?
                                            <p style={{ color: 'red' }}>Das Zeitformat ist falsch.
                                            </p>
                                            : dataResp === 'true' ?
                                                <p style={{ color: 'green' }}>Das Zeitformat ist korrekt.</p>
                                                : <p style={{ color: 'red' }}></p>
                                }
                                <button className="form-input-btn"
                                    type='submit'>
                                    Absenden und an Server senden
                                </button>
                            </form>
                            <Clock />
                        </article>
                    </span>
                </div>
                <div className="item-2">
                    <span className="card">
                    <div className="thumb" style={{ backgroundImage: `url(${MyCode})` }}/>
                    The frontend was written in ReactHooks. Data is sent to the server using json format. 
                    <br/>The backend is written in Java using Spring Framework for web applications
                    <br/>Ropository ReactHooks: <a href='https://github.com/ollo7776/ollo-link-Auth0/blob/main/src/views/JavaApps/Validate24hours.js' >GitHub</a>
                    Ropository JAVA: <a href='https://github.com/ollo7776/CodeWarsBackEnd/blob/main/service/Regex24HoursService.java' >GitHub</a>
                    
                    </span>
                </div>
                <div className="item-3">
                    <span className="card">
                        <div className="thumb" style={{ backgroundImage: `url(${MyCode})` }}></div>
                        <article>
                            <p>In diesem Abschnitt stelle ich einige Anwendungen vor, die in JAVA geschrieben wurden.
                                Dies sind hauptsächlich Programme der Plattform codewars.com, aber auch meine eigenen.
                                Ich wünsche dir viel Spaß.</p>
                        </article>
                    </span>
                </div>

            </div>
        </>
    )
}







