import Icon1 from '../../ProjectsImgs/icons_imgs/gplay-icon.png'
import Icon2 from '../../ProjectsImgs/icons_imgs/xml_icon.png'

import FrontImg from '../../ProjectsImgs/AndroidImgs/GooCalendarImgs/FrontImg.jpg'

export const GooTitle = 'Monats-Plan-Scanner App - Android Version'
export const GooFrontImg = FrontImg
export const GooDescription = 'Eine Anwendung zum Konvertieren eines Papierarbeitsplans in einen digitalen zum Speichern im Google-Kalender.'
export const GooTechDescription = 'Android Studio - Kotlin, XML'
export const GooGitLink = 'https://github.com/ollo7776/GooCalendar'
export const GooPlayLink = 'https://play.google.com/store/apps/details?id=com.ollo.goocalendar&gl=DE'

const iconsList = [
    { 
        id: 1,
        icon: Icon1
    },
    {
        id: 2,
        icon: Icon2
    },    
]

export const GooIconsComponent=()=> {
    return (
        <div className='icons-on-card'>
            {iconsList.map((icon) => (
                <img className="project-icon" key={icon.id} src={icon.icon} alt={icon.id} />
            ))}
        </div>
    );
}
