import JqueryDesktop1 from '../../ProjectsImgs/SrcJqueryDesktop/src_jquery_desktop_1.jpg'
import JqueryDesktop2 from '../../ProjectsImgs/SrcJqueryDesktop/src_jquery_desktop_2.jpg'
import JqueryDesktop3 from '../../ProjectsImgs/SrcJqueryDesktop/src_jquery_desktop_3.jpg'
import JqueryDesktop4 from '../../ProjectsImgs/SrcJqueryDesktop/src_jquery_desktop_4.jpg'
import JqueryDesktop5 from '../../ProjectsImgs/SrcJqueryDesktop/src_jquery_desktop_5.jpg'
import JqueryDesktop6 from '../../ProjectsImgs/SrcJqueryDesktop/src_jquery_desktop_6.jpg'

import JqueryIcon1 from '../../ProjectsImgs/icons_imgs/css3_icon.png'
import JqueryIcon2 from '../../ProjectsImgs/icons_imgs/html5_icon.png'
import JqueryIcon3 from '../../ProjectsImgs/icons_imgs/jquery_icon.png'
import JqueryIcon4 from '../../ProjectsImgs/icons_imgs/js_icon.png'
import JqueryIcon5 from '../../ProjectsImgs/icons_imgs/svg_icon.png'
import JqueryIcon6 from '../../ProjectsImgs/icons_imgs/ubuntu_icon.png'


import FrontImg from '../../ProjectsImgs/SrcJqueryDesktop/src_jquery_desktop_1.jpg'

export const JqDimagesList = [
    {
        id: 1,
        image:
            JqueryDesktop1
    },
    {
        id: 2,
        image:
            JqueryDesktop2
    },
    {
        id: 3,
        image:
            JqueryDesktop3
    },
    {
        id: 4,
        image:
            JqueryDesktop4
    },
    {
        id: 5,
        image:
            JqueryDesktop5
    },
    {
        id: 6,
        image:
            JqueryDesktop6
    },
]

export const JqDTitle = 'JQuery vers. Desktop'
export const JqDFrontImg = FrontImg
export const JqDDescription = 'Ein Programm, das die Möglichkeiten der JQuery-Bibliothek in Kombination mit CSS Animate präsentiert.'
export const JqDTechDescription = 'FRONTEND: REACTHOOKS JS, VERSION FÜR DESKTOP GERÄTE'


const iconsList = [
    {
        id: 1,
        icon: JqueryIcon1
    },
    {
        id: 2,
        icon: JqueryIcon2
    },
    {
        id: 3,
        icon: JqueryIcon3
    },
    {
        id: 4,
        icon: JqueryIcon4
    },
    {
        id: 5,
        icon: JqueryIcon5
    },
    {
        id: 6,
        icon: JqueryIcon6
    },
]

export const JqDIconsComponent=()=> {
    return (
        <div className='icons-on-card'>
            {iconsList.map((icon) => (
                <img className="project-icon" key={icon.id} src={icon.icon} alt={icon.id} />
            ))}
        </div>
    );
}

// export default ImagesComponent;