//View home page with graphics, icons and ....

import React from 'react'
import '../styles/style.css'
import { NavBar } from '../components/menu-bar-up/NavBar'
import { RasterGraphics } from './GrafikenComponents/RasterGraphics'

export const RasterGrafiken = () => {
    return (
        <>
            <div className="item-0">
                <span className='nav-bar'>
                    <NavBar />
                </span>
            </div>
            <RasterGraphics />
        </>
    )

}
