import React, { useState } from 'react'
import { motion } from 'framer-motion'
//import { useState } from 'react/cjs/react.development'

export const Lock = () => {
    const iconVariants = {
        opened: {
            originX: 0.9,
            originY: 1,
            rotate: 30,
            transition: { duration: 1, }
        },
        closed: {
            rotate: 0,
            transition: { duration: 1, }
        }
    }
    // Checks the status of the top menu being pulled out
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <svg
                viewBox='0 -5 30 30'
                width="100px"
                height="100px"
            >
                <motion.path
                    initial='opened'
                    variants={iconVariants}
                    animate={isOpen ? 'opened' : 'closed'}
                   // d='M9 10C9 2 9 2 13 2 17 2 17 2 17 10L19 10C19 0 18 0 13 0 9 0 7 0 7 10L9 10'
                      d='M4 10M9 10C9 2 9 2 13 2 17 2 17 2 17 10L19 10C19 0 18 0 13 0 9 0 7 0 7 10L9 10'   
                   fill="rgb(97, 97, 97)"
                    strokeWidth='2%'
                    stroke='grey'
                    
                />
                <path
                d='M4 10C4 11 4 20 4 21 4 22 22 22 22 21 22 21 22 10 22 10 22 9 4 9 4 10M13 11C16 11 16 15 14 15L14 20 12 20 12 15C10 15 10 11 13 11'
                    // d='M4 10C4 10 4 20 4 21 4 22 22 22 22 21 22 21 22 10 22 10 22 9 4 9 4 10M13 11C16 11 16 15 14 15L14 20 12 20 12 15C10 15 10 11 13 11'
                    fill="red"
                    stroke='grey'
                    strokeWidth='1%'
                />

            </svg>
            <button onClick={() => setIsOpen(value => !value)} >Animation</button>

        </>
    )
}
