import React from 'react'
import { motion } from 'framer-motion'
import ReactPlayer from 'react-player'

export const PopUpSlides = ({ open, onClose, videoAddress }) => {
    if (!open) return null

    return (
        <div className='modal-container-popup'>

            <div className='video-player-wrapper'>
                {/* REACT VIDEO PLAYER */}
                <ReactPlayer className='react-player' 
                // max-width='90%'
                // max-height='90%'
                    // controls url='https://youtube.com/shorts/RvYHUzrlsRk?feature=share' 
                    controls url={videoAddress}
                />
            </div>

            {/* Popup colse button */}
            <div className='modal-container-popup-btn'>
                <div onClick={onClose} className='popup-close-btn'>
                    <svg
                        viewBox="-2.5 -3 15 15"
                        width='40px' height='40px'>
                        <motion.path
                            d="M 5 5.4853 L 1.4645 9.0208 L 0.7574 8.3137 L 4.2929 4.7782 L 0.7574 1.2426 L 1.4645 0.5355 L 5 4.0711 L 8.5355 0.5355 L 9.2426 1.2426 L 5.7071 4.7782 L 9.2426 8.3137 L 8.5355 9.0208 L 5 5.4853"
                            fill="transparent"
                            strokeWidth="0.1"
                            stroke="black"
                            strokeLinecap="round"
                            animate={{ pathLength: [0, 1] }}
                            transition={{ delay: 0, duration: 4 }}
                        />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default PopUpSlides