import Icon1 from '../../ProjectsImgs/icons_imgs/gplay-icon.png'
import Icon2 from '../../ProjectsImgs/icons_imgs/xml_icon.png'

import FrontImg from '../../ProjectsImgs/AndroidImgs/EinkaufslisteImgs/FrontImg.png'

export const EinkaufTitle = 'Einkaufsliste App - Android Version'
export const EinkaufFrontImg = FrontImg
export const EinkaufDescription = 'Einkaufsliste ist ein leichtes, aber sehr funktionales Programm. Jeder Eintrag in der Liste kann einzeln oder mehrere Einträge auf einmal hinzugefügt, geändert oder gelöscht werden, nachdem sie markiert wurden.'
export const EinkaufTechDescription = 'Android Studio - Kotlin, XML, SQLite Database'
export const EinkaufGitLink = 'https://github.com/ollo7776/ShoppingListOllo'
export const EinkaufPlayLink = 'https://play.google.com/store/apps/details?id=com.component.todolistollo&gl=DE'

const iconsList = [
    { 
        id: 1,
        icon: Icon1
    },
    {
        id: 2,
        icon: Icon2
    },    
]

export const EinkaufIconsComponent=()=> {
    return (
        <div className='icons-on-card'>
            {iconsList.map((icon) => (
                <img className="project-icon" key={icon.id} src={icon.icon} alt={icon.id} />
            ))}
        </div>
    );
}
