import React, { useEffect, useState } from 'react'
import useForm from './utils/useForm'
import axios from 'axios'
import url from '../../components/useURL.js'
import { NavBar } from '../../components/menu-bar-up/NavBar'
import MyCode from '../../images/MyCode.png'
import { motion, useAnimation } from 'framer-motion'


export const ScrabbleHelper = () => {
    // Function useAnimation to control letters animation
    const controls = useAnimation()
    const [submitted, setSubmitted] = useState(false)
    const [submittedEN, setSubmittedEN] = useState(false)
    const [error, setError] = useState(false)
    const [errorEN, setErrorEN] = useState(false)

    // Variable to store data from server response (polish vers)
    const [dataResp, setDataResp] = useState([])
    // Variable to store data from server response (english vers)
    const [dataRespEN, setDataRespEN] = useState([])

    // Content type to proper send data to server in json format
    let headerOption = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    // 1.FORM Sending data to the server after validating the form of second part.
    const registerEN = () => {
        console.log(values.name)
        if (valuesEN.name && submittedEN) {
            let dataToSend = `{"letters":"${valuesEN.name}","type":"com.reactcwqr.codewars.service.Letters"}`
            axios.post(url + ':8081/default', dataToSend, headerOption)
                .then((response) => {
                    setDataRespEN(response.data + "")
                    console.log(response)
                })
                .catch(function (error) {
                    console.log(error)
                    setErrorEN(true)
                    //setDataRespEN("Die Daten wurden falsch eingegeben: ")
                })
        }
    }
    // useForm() function imported from utils folder 
    const [valuesEN, handleChangeEN, handleSubmitEN] = useForm(registerEN)


    // 2.FORM Sending data to the server after validating the form of Part One
    const register = () => {
        console.log(values.name)
        if (values.name && submitted) {
            let dataToSend = `{"letters":"${values.name}","type":"com.reactcwqr.codewars.service.scrabble.ScrabbleHelper"}`
            axios.post(url + ':8081/default', dataToSend, headerOption)
                .then((response) => {
                    setDataResp(response.data + "")
                    console.log(response)
                })
                .catch(function (error) {
                    console.log(error)
                    setError(true)
                    //setDataResp("Die Daten wurden falsch eingegeben: ")
                })
        }
    }
    // useForm() function imported from utils folder 
    const [values, handleChange, handleSubmit] = useForm(register)


    useEffect(() => {
        if (submitted) {
            controls.start(i => ({
                opacity: [0, 1],
                rotate: [-360, 0, 360],
                //x: 100,
                transition: { delay: i * 0.5, duration: 3 },
            })
            )

        }
    }, [submitted, controls])
    return (
        <>
            <div className="body-container">
                <div className="item-0">
                    <span className='nav-bar'>
                        <NavBar />
                    </span>
                </div>

                <div className="item-1">
                    <span className="card">
                        <article>
                            <div>
                                <motion.div className='colored-letters' >
                                    <motion.div custom={0} animate={controls} >L</motion.div>
                                    <motion.div custom={1} animate={controls} >E</motion.div>
                                    <motion.div custom={2} animate={controls} >T</motion.div>
                                    <motion.div custom={3} animate={controls} >T</motion.div>
                                    <motion.div custom={4} animate={controls} >E</motion.div>
                                    <motion.div custom={5} animate={controls} >R</motion.div>
                                    <motion.div custom={6} animate={controls} >S</motion.div>
                                </motion.div>
                            </div>

                            <div>
                                <form onSubmit={handleSubmitEN}>
                                    <label>Buchstaben:</label>
                                    <input
                                        value={valuesEN.name || ""}
                                        onChange={handleChangeEN}
                                        name="name"
                                        type='text'
                                    />
                                    <button onClick={() => setSubmittedEN(true)}>Senden</button> (ohne Pkt.)
                                </form>
                                <p>Antwort vom Server:</p>
                                {
                                    submittedEN && !valuesEN.name ?
                                        <p style={{ color: 'red' }}>No code is pasted</p>
                                        : errorEN ?
                                            <p style={{ backgroundColor: 'yellow' }}>Die Daten wurden falsch eingegeben: </p>
                                            : <div style={{ color: 'green' }}>
                                                {dataRespEN.toString().split('newLine').map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {item}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                }

                            </div>
                            <div>
                                <form onSubmit={handleSubmit}>
                                    <label>Buchstaben:</label>
                                    <input
                                        value={values.name || ""}
                                        onChange={handleChange}
                                        name="name"
                                        type='text'
                                    />
                                    <button onClick={() => setSubmitted(true)}>Senden</button> (mit Pkt.)
                                </form>
                                <p>Antwort vom Server:</p>
                                {
                                    submitted && !values.name ?
                                        <p style={{ color: 'red' }}>No code is pasted</p>
                                        : error ?
                                            <p style={{ backgroundColor: 'yellow' }}>Die Daten wurden falsch eingegeben: </p>
                                            : <div style={{ color: 'green' }}>
                                                {dataResp.toString().split('newLine').map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {item}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                }
                            </div>
                        </article>
                    </span>

                </div>

                <div className="item-2">
                    <span className="card">
                        <article>
                            <div>
                                <motion.div className='colored-letters' >
                                    <motion.div custom={0} animate={controls} >L</motion.div>
                                    <motion.div custom={1} animate={controls} >E</motion.div>
                                    <motion.div custom={2} animate={controls} >T</motion.div>
                                    <motion.div custom={3} animate={controls} >T</motion.div>
                                    <motion.div custom={4} animate={controls} >E</motion.div>
                                    <motion.div custom={5} animate={controls} >R</motion.div>
                                    <motion.div custom={6} animate={controls} >S</motion.div>
                                </motion.div>
                            </div>
                            <div>
                                <p>
                                    Dieses Programm hilft Ihnen beim Scrabble-Spielen.
                                    Geben Sie einfach die Buchstaben ein, aus denen das Wort zusammengesetzt werden soll,
                                    und Sie erhalten alle Kombinationswörter, die sich aus diesem Buchstabensatz zusammensetzen lassen.
                                </p>
                            </div>
                        </article>
                    </span>
                </div >

                <div className="item-3">
                    <span className="card">
                        <div className="thumb" style={{ backgroundImage: `url(${MyCode})` }}></div>
                        <article>
                            <p>
                                The frontend was written in ReactHooks. Animations are made with FramerMotion
                                Data is sent to the server using json format.
                                The backend is written in Java using Spring Framework for web applications
                            </p>
                        </article>
                    </span>
                </div>

            </div >
        </>

    )
}
