import Img1 from '../../ProjectsImgs/ScrRepairAppDesktop/src_repairapp_desk_1.jpg'
import Img2 from '../../ProjectsImgs/ScrRepairAppDesktop/src_repairapp_desk_2.jpg'
import Img3 from '../../ProjectsImgs/ScrRepairAppDesktop/src_repairapp_desk_3.jpg'
import Img4 from '../../ProjectsImgs/ScrRepairAppDesktop/src_repairapp_desk_4.jpg'
import Img5 from '../../ProjectsImgs/ScrRepairAppDesktop/src_repairapp_desk_5.jpg'
import Img6 from '../../ProjectsImgs/ScrRepairAppDesktop/src_repairapp_desk_6.jpg'
import Img7 from '../../ProjectsImgs/ScrRepairAppDesktop/src_repairapp_desk_7.jpg'

import Icon1 from '../../ProjectsImgs/icons_imgs/react_icon.png'
import Icon2 from '../../ProjectsImgs/icons_imgs/css3_icon.png'
import Icon3 from '../../ProjectsImgs/icons_imgs/html5_icon.png'
import Icon4 from '../../ProjectsImgs/icons_imgs/js_icon.png'
import Icon5 from '../../ProjectsImgs/icons_imgs/java_icon.png'
import Icon6 from '../../ProjectsImgs/icons_imgs/spring_icon.png'
import Icon7 from '../../ProjectsImgs/icons_imgs/ubuntu_icon.png'

import FrontImg from '../../ProjectsImgs/ScrRepairAppDesktop/src_repairapp_desk_1.jpg'

export const RDImagesList = [
    {
        id: 1,
        image:
            Img1
    },
    {
        id: 2,
        image:
            Img2
    },
    {
        id: 3,
        image:
            Img3
    },
    {
        id: 4,
        image:
            Img4
    },
    {
        id: 5,
        image:
            Img5
    },
    {
        id: 6,
        image:
            Img6
    },
    {
        id: 7,
        image:
            Img7
    },
]

export const RDTitle = 'Repair Web App vers. Desktop'
export const RDFrontImg = FrontImg
export const RDDescription = 'Ein Programm zur Meldung von Störungen an Fahrzeugen'
export const RDTechDescription = 'Frontend: ReactHooks JS, Backend: Java, Sppring RestApi. Version für mobile Geräte.'                        

const iconsList = [
    {
        id: 1,
        icon: Icon1
    },
    {
        id: 2,
        icon: Icon2
    },
    {
        id: 3,
        icon: Icon3
    },
    {
        id: 4,
        icon: Icon4
    },
    {
        id: 5,
        icon: Icon5
    },
    {
        id: 6,
        icon: Icon6
    },
    {
        id: 7,
        icon: Icon7
    },   
]

export const RDIconsComponent=()=> {
    return (
        <div className='icons-on-card'>
            {iconsList.map((icon) => (
                <img className="project-icon" key={icon.id} src={icon.icon} alt={icon.id} />
            ))}
        </div>
    );
}
