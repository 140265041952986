import Img1 from '../../ProjectsImgs/ScrConvertMobile/src_convert7776_mobile_1.jpg'
import Img2 from '../../ProjectsImgs/ScrConvertMobile/src_convert7776_mobile_2.jpg'
import Img3 from '../../ProjectsImgs/ScrConvertMobile/src_convert7776_mobile_3.jpg'
import Img4 from '../../ProjectsImgs/ScrConvertMobile/src_convert7776_mobile_4.jpg'
import Img5 from '../../ProjectsImgs/ScrConvertMobile/src_convert7776_mobile_5.jpg'
import Img6 from '../../ProjectsImgs/ScrConvertMobile/src_convert7776_mobile_6.jpg'
import Img7 from '../../ProjectsImgs/ScrConvertMobile/src_convert7776_mobile_7.jpg'

import Icon1 from '../../ProjectsImgs/icons_imgs/react_icon.png'
import Icon2 from '../../ProjectsImgs/icons_imgs/css3_icon.png'
import Icon3 from '../../ProjectsImgs/icons_imgs/html5_icon.png'
import Icon4 from '../../ProjectsImgs/icons_imgs/js_icon.png'
import Icon5 from '../../ProjectsImgs/icons_imgs/framer_motion_icon.png'

import FrontImg from '../../ProjectsImgs/ScrConvertMobile/src_convert7776_mobile_1.jpg'

export const CMImagesList = [
    {
        id: 1,
        image:
            Img1
    },
    {
        id: 2,
        image:
            Img2
    },
    {
        id: 3,
        image:
            Img3
    },
    {
        id: 4,
        image:
            Img4
    },
    {
        id: 5,
        image:
            Img5
    },
    {
        id: 6,
        image:
            Img6
    },
    {
        id: 7,
        image:
            Img7
    },
]

export const CMTitle = 'Convert Web App vers. Mobile'
export const CMFrontImg = FrontImg
export const CMDescription = 'Ein Programm zum Konvertieren eines Papierarbeitsplans.'
export const CMTechDescription = 'FRONTEND: REACTHOOKS JS, FRAMERMOTION, VERSION FÜR Mobile Geräte.'


const iconsList = [
    {
        id: 1,
        icon: Icon1
    },
    {
        id: 2,
        icon: Icon2
    },
    {
        id: 3,
        icon: Icon3
    },
    {
        id: 4,
        icon: Icon4
    },
    {
        id: 5,
        icon: Icon5
    },   
]

export const CMIconsComponent=()=> {
    return (
        <div className='icons-on-card'>
            {iconsList.map((icon) => (
                <img className="project-icon" key={icon.id} src={icon.icon} alt={icon.id} />
            ))}
        </div>
    );
}
