import Img1 from '../../ProjectsImgs/SrcQueryDesktop/src_img_1.png'
import Img2 from '../../ProjectsImgs/SrcQueryDesktop/src_img_2.png'
import Img3 from '../../ProjectsImgs/SrcQueryDesktop/src_img_3.png'
import Img4 from '../../ProjectsImgs/SrcQueryDesktop/src_img_4.png'
import Img5 from '../../ProjectsImgs/SrcQueryDesktop/src_img_5.png'
import Img6 from '../../ProjectsImgs/SrcQueryDesktop/src_img_6.png'
import Img7 from '../../ProjectsImgs/SrcQueryDesktop/src_img_7.png'
import Img8 from '../../ProjectsImgs/SrcQueryDesktop/src_img_8.png'
import Img9 from '../../ProjectsImgs/SrcQueryDesktop/src_img_9.png'
import Img10 from '../../ProjectsImgs/SrcQueryDesktop/src_img_10.png'
import Img11 from '../../ProjectsImgs/SrcQueryDesktop/src_img_11.png'
import Img12 from '../../ProjectsImgs/SrcQueryDesktop/src_img_12.png'

import Icon1 from '../../ProjectsImgs/icons_imgs/react_icon.png'
import Icon2 from '../../ProjectsImgs/icons_imgs/css3_icon.png'
import Icon3 from '../../ProjectsImgs/icons_imgs/html5_icon.png'
import Icon4 from '../../ProjectsImgs/icons_imgs/js_icon.png'
import Icon5 from '../../ProjectsImgs/icons_imgs/framer_motion_icon.png'
import Icon6 from '../../ProjectsImgs/icons_imgs/ubuntu_icon.png'
import Icon7 from '../../ProjectsImgs/icons_imgs/apache_icon.png'

import FrontImg from '../../ProjectsImgs/SrcQueryDesktop/src_img_1.png'

export const QDImagesList = [
    {
        id: 1,
        image:
            Img1
    },
    {
        id: 2,
        image:
            Img2
    },
    {
        id: 3,
        image:
            Img3
    },
    {
        id: 4,
        image:
            Img4
    },
    {
        id: 5,
        image:
            Img5
    },
    {
        id: 6,
        image:
            Img6
    },
    {
        id: 7,
        image:
            Img7
    },
    {
        id: 8,
        image:
            Img8
    },
    {
        id: 9,
        image:
            Img9
    },
    {
        id: 10,
        image:
            Img10
    },
    {
        id: 11,
        image:
            Img11
    },
    {
        id: 12,
        image:
            Img12
    },
]

export const QDTitle = 'Pflege Befragung vers. Desktop'
export const QDFrontImg = FrontImg
export const QDDescription = 'Eine Bewerbung, die ein Beispiel für ein umfangreiches Formular darstellt (z. B. im medizinischen Bereich).'
export const QDTechDescription = 'Tech.: React Hooks, JS, CSS, HTML, Ubuntu'

const iconsList = [
    {
        id: 1,
        icon: Icon1
    },
    {
        id: 2,
        icon: Icon2
    },
    {
        id: 3,
        icon: Icon3
    },
    {
        id: 4,
        icon: Icon4
    },
    {
        id: 5,
        icon: Icon5
    },
    {
        id: 6,
        icon: Icon6
    },    
    {
        id: 7,
        icon: Icon7
    },      
]

export const QDIconsComponent=()=> {
    return (
        <div className='icons-on-card'>
            {iconsList.map((icon) => (
                <img className="project-icon" key={icon.id} src={icon.icon} alt={icon.id} />
            ))}
        </div>
    );
}
