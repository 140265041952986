import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";

export function Clock() {
    const [isMove, setIsMove] = useState(true);
    const controls = useAnimation()

    let width = 120
    let height = 80

    const variants = {
        initial: {
            opacity: 1
        }
    }

    useEffect(() => {
        if (isMove) {
            // controls.start({
            //     opacity: [0.5, 0.8, 0.2, 1]
            // })
            // controls.start({
            //     transition: { duration: 3 },
            // })
            controls.start(i => ({
                //opacity: [ 1, 0, 0.5, 0.8, 0.2, 1, 0.6],
                opacity: [ 1, 0.7, 0.2, 1 ],
                transition: { delay: i * 2, duration: 3, repeat: 2 },
            })
            )
        } else {
            controls.start({
                transition: { duration: 3 },
            })
            controls.start({
                opacity: [0.5, 0.8, 0.2, 0], transition: { delay: 0 }
            })
        }
    }, [isMove, controls])

    

    // const [animation, cycleAnimation] = useCycle( 'animationOne', 'animationTwo', 'animationThree', 'animationFour')
    // const loaderVariants = {
    //     animationOne: {
    //         opacity: [1,0],
    //         transition: {
               
    //         }
    //     },
    //     animationTwo: {
    //         opacity: [1,0],
    //         transition: {
               
    //         }
    //     },animationThree: {
    //         opacity: [1,0],
    //         transition: {
               
    //         }
    //     },animationFour: {
    //         opacity: [1,0],
    //         transition: {
               
    //         }
    //     },
    // }


    return (
        <>
            <div className="circle-line-rect">
                <motion.svg
                    viewBox="16 -15 100 100"
                    width={width} height={height}
                >
                    <path
                        //d="M2 2 26 2 26 15 2 15 2 2 2 2"
                        d="M0 0 133 0 133 56 0 56 0 0 0 0"
                        fill="black"
                        strokeWidth="3"
                        stroke="darkgrey"
                        strokeLinecap="round"
                       
                    />

                    {/* First 8 on Clock */}
                    <motion.path
                        d="
                        M8 7 30 7 26 11 12 11 8 7
                        M27 12 31 8 31 27 29 27 27 25 27 12
                        M28 28 26 30 12 30 10 28 12 26 26 26 28 28
                        M7 8 11 12 11 25 9 27 7 27 7 8
                        M31 29 31 48 27 44 27 31 29 29 31 29
                        M7 29 9 29 11 31 11 44 7 48 7 29
                        M12 45 26 45 30 49 8 49 12 45"
                        fill="red"
                        variants={variants}
                        initial='initial'
                        animate={controls}
                        custom={0}
                    />

                    {/* Second 8 on Clock */}
                    <motion.path
                        d="
                        M38 7 60 7 56 11 42 11 38 7
                        M57 12 61 8 61 27 59 27 57 25 57 12
                        M58 28 56 30 42 30 40 28 42 26 56 26 58 28
                        M37 8 41 12 41 25 39 27 37 27 37 8
                        M61 29 61 48 57 44 57 31 59 29 61 29
                        M37 29 39 29 41 31 41 44 37 48 37 29
                        M42 45 56 45 60 49 38 49 42 45"
                        fill="red"
                        variants={variants}
                        initial='initial'
                        animate={controls}
                        custom={1}
                    />

                    {/* points between 88:88 */}
                    <motion.path
                        d="
                        M65 18 68 18 68 21 65 21 65 18M65 35 68 35 68 38 65 38 65 35
                        "
                        fill="red"                      
                        variants={variants}
                        initial='initial'
                        animate={controls}
                        custom={2}
                    />

                    {/* Third 8 on Clock */}
                    <motion.path
                        d="M73 7 95 7 91 11 77 11 73 7
                        M92 12 96 8 96 27 94 27 92 25 92 12
                        M93 28 91 30 77 30 75 28 77 26 91 26 93 28
                        M72 8 76 12 76 25 74 27 72 27 72 8
                        M96 29 96 48 92 44 92 31 94 29 96 29
                        M72 29 74 29 76 31 76 44 72 48 72 29
                        M77 45 91 45 95 49 73 49 77 45"

                        //d="M73 7 95 7 91 11 77 11 73 7   M93 28 91 30 77 30 75 28 77 26 91 26 93 28M72 8 76 12 76 25 74 27 72 27 72 8M96 29 96 48 92 44 92 31 94 29 96 29M72 29 74 29 76 31 76 44 72 48 72 29M77 45 91 45 95 49 73 49 77 45M103 7 125 7 121 11 107 11 103 7M122 12 126 8 126 27 124 27 122 25 122 12M123 28 121 30 107 30 105 28 107 26 121 26 123 28M102 8 106 12 106 25 104 27 102 27 102 8M126 29 126 48 122 44 122 31 124 29 126 29M102 29 104 29 106 31 106 44 102 48 102 29M107 45 121 45 125 49 103 49 107 45"
                        fill="red"                       
                        variants={variants}
                        initial='initial'
                        animate={controls}
                        custom={3}
                    />

                    {/* Fourth 8 on Clock */}
                    <motion.path
                        d="
                        M103 7 125 7 121 11 107 11 103 7
                        M122 12 126 8 126 27 124 27 122 25 122 12
                        M123 28 121 30 107 30 105 28 107 26 121 26 123 28
                        M102 8 106 12 106 25 104 27 102 27 102 8
                        M126 29 126 48 122 44 122 31 124 29 126 29
                        M102 29 104 29 106 31 106 44 102 48 102 29
                        M107 45 121 45 125 49 103 49 107 45"

                        //d="M73 7 95 7 91 11 77 11 73 7   M93 28 91 30 77 30 75 28 77 26 91 26 93 28M72 8 76 12 76 25 74 27 72 27 72 8M96 29 96 48 92 44 92 31 94 29 96 29M72 29 74 29 76 31 76 44 72 48 72 29M77 45 91 45 95 49 73 49 77 45M103 7 125 7 121 11 107 11 103 7M122 12 126 8 126 27 124 27 122 25 122 12M123 28 121 30 107 30 105 28 107 26 121 26 123 28M102 8 106 12 106 25 104 27 102 27 102 8M126 29 126 48 122 44 122 31 124 29 126 29M102 29 104 29 106 31 106 44 102 48 102 29M107 45 121 45 125 49 103 49 107 45"
                        fill="red"                       
                        variants={variants}
                        initial='initial'
                        animate={controls}
                        custom={4}
                    />

                </motion.svg>
            </div>


            {/* ANIMATION Button with small icon SVG clues */}
            <button onClick={() => setIsMove(!isMove)}>
                {/* <svg
                    viewBox="-3 -4 30 30"
                    width='25' height='25'
                >
                    <path
                        d="M92 12 96 8 96 27 94 27 92 25 92 12"

                        strokeWidth="1"
                        stroke="green"
                        fill='lightgreen'
                        strokeLinecap="round"
                    />
                </svg> */}
                Animation</button>
        </>
    );
}



