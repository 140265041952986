import React, { useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
//  import { ConvertDesktopData } from '../SliderProjectsData'

export const Slider = ({ imgsArr }) => {
    const [current, setCurrent] = useState(0)
    const slides = imgsArr    

    const length = slides.length
    const controls = useAnimation()

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1)
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1)
    }

    if (!Array.isArray(slides) || slides.length <= 0) {
        return null
    }
    const setSlide = (i) => {
        setCurrent(i)
        console.log(i)
    }

    return (
        <>
            <div className='projects-slider-page'>
                <div className='projects-slider'>
                    <div className='project-left-arrow'>
                        {/* Arrow button to move the slides to the left */}
                        <svg onClick={nextSlide}
                        width="40"  height="50"                      
                        viewBox='0 0 8 8'                             
                        >
                            <path
                                d='M 1 5 L 5 1 L 6 1 L 2 5 L 6 9 L 5 9 L 1 5'
                            // d='M6 30C6-3 57-3 57 30 57 63 6 63 6 30L6 30M27 45 27 36 48 36 48 24 27 24 27 15 15 30 27 45'
                            />
                        </svg>
                    </div>
                    <div className='project-slider-center'>
                        {slides.map((slide, i) => {
                            return (
                                <div className={i === current ? 'pro-slide active' : 'pro-slide'} key={slide.id}>
                                    {i === current && (
                                        <img className='project-slider-img' src={slide.image} alt='img' />
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    {/* Arrow button to move the slides to the right */}
                    <div className='project-right-arrow'>
                        <svg onClick={prevSlide}
                        width="40"  height="50"                      
                             viewBox='0 0 8 8'
                        >
                            <path d='M 5 5 L 1 1 L 2 1 L 6 5 L 2 9 L 1 9 L 5 5' />
                        </svg>
                    </div>
                </div>
                {/* SECTION WITH THUMBS SLIDER */}
                <motion.section animate={controls} className='project-thumbs-pictures'>
                    {slides.map((slide, i) => {
                        return (
                            <img src={slide.image} key={slide.id} alt='img'
                                className='project-thumbs-img'
                                onClick={() => setSlide(i)} />
                        )
                    })}
                </motion.section>
                {/* END OF SECTION WITH THUMBS SLIDER */}
            </div>
        </>
    )
}

export default Slider