import React, { useState } from 'react'
import { motion } from 'framer-motion'

export const OnOff = () => {
    const iconVariants = {
        closed: { opacity: 0, transition: {duration: 1} },
        opened: {  opacity: 1, transition: {duration: 1} },
        
        // (i) => {
        //   const delay = 1 + i * 0.5;
        //   return {
        //     pathLength: 1,
        //     opacity: 1,
        //     transition: {
        //       pathLength: { delay, type: "spring", duration: 3, bounce: 0 },
        //       opacity: { delay, duration: 0.01 }
        //     }
        //   };
        // }
    }
    // Checks the status of the top menu being pulled out
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <svg
                viewBox='-10 -15 40 40'
                width="100px"
                height="100px"
            >
                <path
                    d='M6 3C0 4.5 0 18 10.5 18 21 18 21 4.5 15 3L15 0C25.5 1.5 25.5 21 10.5 21-4.5 21-4.5 1.5 6 0L6 3 9 1.5 9 1.5 12 1.5 12-3C12-4.5 9-4.5 9-3L9 1.5 12 1.5 12 9C12 10.5 9 10.5 9 9L9 1.5'
                    fill="#1a5200"
                /> 
                <motion.path
                    initial='opened'
                    variants={iconVariants}
                    animate={isOpen ? 'opened' : 'closed'}
                    //stroke="#ff0055"
                    d='M6 3C0 4.5 0 18 10.5 18 21 18 21 4.5 15 3L15 0C25.5 1.5 25.5 21 10.5 21-4.5 21-4.5 1.5 6 0L6 3 9 1.5 9 1.5 12 1.5 12-3C12-4.5 9-4.5 9-3L9 1.5 12 1.5 12 9C12 10.5 9 10.5 9 9L9 1.5'
                    fill="red"
                />
                                      
            </svg>
            <button onClick={() => setIsOpen(value => !value)} >Animation</button>

        </>
    )
}
