import React, { useState, useEffect } from 'react'
// import './Spielchen.css'
import { motion, useAnimation } from 'framer-motion'
import CookieMonsterSVG from './CookiesMonster'

function CookiesBanner() {
    const [isOpen, setIsOpen] = useState(true)
    const controls = useAnimation()
    const variants = {
        initial: {
            opacity: 1
        }
    }

    useEffect(() => {
        if (isOpen) {
            controls.start(
                { opacity: [.2, 1] }
            )
        } else {
            controls.start({opacity: [1, 0], 
                //  scale: [1,0], 
                // transition: { duration: 1 } 
            })
            controls.start({
                scale: [1,0],
                transition: { delay: 1 }
            })
            // controls.start({
            //     transition: { duration: 3 }
            // })
            // controls.start({
            //     opacity: [0.5, 0.8, 0.2, 0], transition: { delay: 0 }
            // })
        }
    }, [isOpen, controls])

    return (
        <>
            <motion.div
                animate={controls}
                variants={variants}
            >
                <div className="cookies-container">
                    <div className='cookies-banner'>
                        <div><CookieMonsterSVG className="cookies-svg" /></div>                        
                        <p className='cookies-text'>...Für die Seite, auf der Sie sich befinden,
                            müssen keine Cookies akzeptiert werden.
                            Wir speichern keine Daten,
                            aber Sie haben das Recht zu bestätigen oder abzulehnen.
                            Wir verfolgen niemanden und speichern keine sensiblen Daten.
                            Wir sind fest überzeugt, es ist besser,
                            kleine Cookies zu essen, als sich an alle zu erinnern.
                        </p>
                    </div>
                    <div className='cookies-btns'>
                        {/* <button onClick={() => setIsOpen()}>JA</button>
                        <button onClick={() => setIsOpen()}>NEIN</button> */}
                        {/* <button onClick={() => setIsOpen()}>...gerne Cookies essen</button> */}
                    </div>
                </div>

            </motion.div>


        </>
    )
}


export default CookiesBanner;

