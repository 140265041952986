import React from 'react'
import { NavBar } from '../components/menu-bar-up/NavBar'
//import { Link } from "react-router-dom";
// import { RepairAppMobile } from './ProjectsComponents/RepairAppMobile'
// import { RepairAppDesktop } from './ProjectsComponents/RepairAppDesktop'
import { CardOnProjects } from './ProjectsComponents/CardOnProjects'
import { AusfallAppTitle, AusfallAppDescription, AusfallAppTechDescription, AusfallAppFrontImg, AusfallAppIconsComponent, AusfallAppGitLink, AusfallAppPlayLink } from './ProjectsComponents/ProjectsData/AndroidApps/AusfallAppData'
import { GooTitle, GooDescription, GooTechDescription, GooFrontImg, GooIconsComponent, GooGitLink, GooPlayLink } from './ProjectsComponents/ProjectsData/AndroidApps/GooCalendarApp'
import { EinkaufTitle, EinkaufDescription, EinkaufTechDescription, EinkaufFrontImg, EinkaufIconsComponent, EinkaufGitLink, EinkaufPlayLink } from './ProjectsComponents/ProjectsData/AndroidApps/EinkaufsListeApp'
//import { EBListTitle, EBListDescription, EBListTechDescription, EBListFrontImg, EBListIconsComponent, EBListPlayLink } from './ProjectsComponents/ProjectsData/AndroidApps/EBList'
import { EBListTitle, EBListImagesList, EBListDescription, EBListTechDescription, EBListFrontImg, EBListIconsComponent } from './ProjectsComponents/ProjectsData/NewApps/NewApp'

import { PimkGonkTitle, PimkGonkDescription, PimkGonkTechDescription, PimkGonkFrontImg, PimkGonkIconsComponent, PimkGonkPlayLink } from './ProjectsComponents/ProjectsData/PinkGonk/PimkGonkData'

import { RDTitle, RDImagesList, RDDescription, RDTechDescription, RDFrontImg, RDIconsComponent } from './ProjectsComponents/ProjectsData/RepairWebApp/RepairDesktopData'
import { RMTitle, RMImagesList, RMDescription, RMTechDescription, RMFrontImg, RMIconsComponent } from './ProjectsComponents/ProjectsData/RepairWebApp/RepairMobileData'
import { CDTitle, CDImagesList, CDDescription, CDTechDescription, CDFrontImg, CDIconsComponent } from './ProjectsComponents/ProjectsData/ConvertWebAppData/ConvertDesktopData'
import { CMTitle, CMImagesList, CMDescription, CMTechDescription, CMFrontImg, CMIconsComponent } from './ProjectsComponents/ProjectsData/ConvertWebAppData/ConvertMobileData'
import { JqMTitle, JqMimagesList, JqMDescription, JqMTechDescription, JqMFrontImg, JqMIconsComponent } from './ProjectsComponents/ProjectsData/JQueryData/JqMobileData'
import { JqDTitle, JqDimagesList, JqDDescription, JqDTechDescription, JqDFrontImg, JqDIconsComponent } from './ProjectsComponents/ProjectsData/JQueryData/JqDesktopData'
import { XamlTitle, XamlImagesList, XamlDescription, XamlTechDescription, XamlFrontImg, XamlIconsComponent } from './ProjectsComponents/ProjectsData/XamlCsTiU/XamlCsTiUData'
import { QDTitle, QDImagesList, QDDescription, QDTechDescription, QDFrontImg, QDIconsComponent } from './ProjectsComponents/ProjectsData/QueryData/QueryDesktopData'
import { QMTitle, QMImagesList, QMDescription, QMTechDescription, QMFrontImg, QMIconsComponent } from './ProjectsComponents/ProjectsData/QueryData/QueryMobileData'
import { CardAndroidApp } from './ProjectsComponents/CardAnroidApp'

export const Projects = () => {
   
    return (
        <>        
            <div className="projects-body">
                <div className="item-0">
                    <span className='nav-bar'>
                        <NavBar />
                    </span>
                </div>
                <div className='projects-center'>

                    <div className="project-card">
                        <div className="card">
                            {/* AUSFALLAPP ANDROID */}
                            <CardAndroidApp
                                title={AusfallAppTitle}
                                FrontImg={AusfallAppFrontImg}
                                description={AusfallAppDescription}
                                techDescription={AusfallAppTechDescription}
                                // imgsArr={RDImagesList}
                                IconsArr={AusfallAppIconsComponent}
                                GitLink={AusfallAppGitLink}
                                PlayLink={AusfallAppPlayLink}                                
                            />
                        </div>
                    </div>

                    <div className="project-card">
                        <div className="card">
                            {/* MonatsPlanScanner ANDROID */}
                            <CardAndroidApp
                                title={GooTitle}
                                FrontImg={GooFrontImg}
                                description={GooDescription}
                                techDescription={GooTechDescription}
                                // imgsArr={RDImagesList}
                                IconsArr={GooIconsComponent}
                                GitLink={GooGitLink}
                                PlayLink={GooPlayLink}
                            />
                        </div>
                    </div>

                    <div className="project-card">
                        <div className="card">
                            {/* EinkaufsListe ANDROID ShoppingList*/}
                            <CardAndroidApp
                                title={EinkaufTitle}
                                FrontImg={EinkaufFrontImg}
                                description={EinkaufDescription}
                                techDescription={EinkaufTechDescription}
                                // imgsArr={RDImagesList}
                                IconsArr={EinkaufIconsComponent}                                
                                GitLink={EinkaufGitLink}
                                PlayLink={EinkaufPlayLink}
                            />
                        </div>
                    </div>
                   
                    <div className="project-card">
                        <div className="card">                          
                            {/* NEW APP VERS Mobile */}
                            <CardOnProjects
                                title={EBListTitle}
                                FrontImg={EBListFrontImg}
                                description={EBListDescription}
                                techDescription={EBListTechDescription}
                                imgsArr={EBListImagesList}
                                IconsArr={EBListIconsComponent}
                                videoAddress={EBListIconsComponent}
                            />                            
                        </div>
                    </div>

                    <div className="project-card">
                        <div className="card">                        
                            {/*PimkGonk Spiel */}
                            <CardAndroidApp
                                title={PimkGonkTitle}
                                FrontImg={PimkGonkFrontImg}
                                description={PimkGonkDescription}
                                techDescription={PimkGonkTechDescription}
                                // imgsArr={RDImagesList}
                                IconsArr={PimkGonkIconsComponent}
                                PlayLink={PimkGonkPlayLink}
                            />                           
                        </div>
                    </div>

                    <div className="project-card">
                        <div className="card">
                            {/* RepairApp VERS DESKTOP */}
                            <CardOnProjects
                                title={RDTitle}
                                FrontImg={RDFrontImg}
                                description={RDDescription}
                                techDescription={RDTechDescription}
                                imgsArr={RDImagesList}
                                IconsArr={RDIconsComponent}
                                videoAddress='https://youtu.be/ka7NTWUR2gQ'
                            />
                        </div>
                    </div>
                    <div className="project-card">
                        <div className="card">
                            {/* <article> */}
                            {/* RepairApp VERS Mobile */}
                            <CardOnProjects
                                title={RMTitle}
                                FrontImg={RMFrontImg}
                                description={RMDescription}
                                techDescription={RMTechDescription}
                                imgsArr={RMImagesList}
                                IconsArr={RMIconsComponent}
                                videoAddress='https://youtu.be/Wv6sSUyXWaE'
                            />
                            {/* </article> */}
                        </div>
                    </div>
                    <div className="project-card">
                        <div className="card">
                            {/* <article> */}
                            {/* CONVERT VERS DESTOP */}
                            <CardOnProjects
                                title={CDTitle}
                                FrontImg={CDFrontImg}
                                description={CDDescription}
                                techDescription={CDTechDescription}
                                imgsArr={CDImagesList}
                                IconsArr={CDIconsComponent}
                                videoAddress='https://youtu.be/vrMlQcutwtI'
                            />
                            {/* </article> */}
                        </div>
                    </div>
                    <div className="project-card">
                        <div className="card">
                            {/* <article> */}
                            {/* CONVERT VERS Mobile */}
                            <CardOnProjects
                                title={CMTitle}
                                FrontImg={CMFrontImg}
                                description={CMDescription}
                                techDescription={CMTechDescription}
                                imgsArr={CMImagesList}
                                IconsArr={CMIconsComponent}
                                videoAddress='https://youtube.com/shorts/Rb8efbRZd1E?feature=share'
                            />
                            {/* </article> */}
                        </div>
                    </div>
                    <div className="project-card">
                        <div className="card">
                            {/* Jquery Desktop  */}
                            <CardOnProjects
                                title={JqDTitle}
                                FrontImg={JqDFrontImg}
                                description={JqDDescription}
                                techDescription={JqDTechDescription}
                                imgsArr={JqDimagesList}
                                IconsArr={JqDIconsComponent}
                                videoAddress='https://youtu.be/2zuCVU58DpA'
                            />
                        </div>
                    </div>
                    <div className="project-card">
                        <div className="card">
                            {/* JQUERY Mobile */}
                            <CardOnProjects
                                title={JqMTitle}
                                FrontImg={JqMFrontImg}
                                description={JqMDescription}
                                techDescription={JqMTechDescription}
                                imgsArr={JqMimagesList}
                                IconsArr={JqMIconsComponent}
                                videoAddress='https://youtube.com/shorts/ALqQgA5Xhlw'
                            />
                        </div>
                    </div>
                    <div className="project-card">
                        <div className="card">
                            {/* Xaml VS2022 */}
                            <CardOnProjects
                                title={XamlTitle}
                                FrontImg={XamlFrontImg}
                                description={XamlDescription}
                                techDescription={XamlTechDescription}
                                imgsArr={XamlImagesList}
                                IconsArr={XamlIconsComponent}
                                videoAddress='https://youtu.be/J6ielrpYkw8'
                            />
                        </div>
                    </div>
                    <div className="project-card">
                        <div className="card">
                            {/* Query Desktop */}
                            <CardOnProjects
                                title={QDTitle}
                                FrontImg={QDFrontImg}
                                description={QDDescription}
                                techDescription={QDTechDescription}
                                imgsArr={QDImagesList}
                                IconsArr={QDIconsComponent}
                                videoAddress='https://youtu.be/IxVUS3cHz9I'
                            />
                        </div>
                    </div>
                    <div className="project-card">
                        <div className="card">
                            {/* Query Mobile */}
                            <CardOnProjects
                                title={QMTitle}
                                FrontImg={QMFrontImg}
                                description={QMDescription}
                                techDescription={QMTechDescription}
                                imgsArr={QMImagesList}
                                IconsArr={QMIconsComponent}
                                videoAddress='https://youtu.be/QU8ErRAfvUA'
                            />
                        </div>
                    </div>
                </div>

                {/* FOOTER */}
                <div className="project-footer">
                    <span className="card">
                        <span> www.ollo.link - - adress: Eschborn - - email: ollo7776@gmail.com - - telefon: 015207073112 </span>
                    </span>
                </div>
                {/* END FOOTER */}

            </div>            
        </>


    )
}
