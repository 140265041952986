// Data for the slide show on the Grafiken website
import CarIcons from './GraphicImages/CarIcons.png' 
import InfoIcons from './GraphicImages/InfoIcons.png'
import BackEndGradUpDown from './GraphicImages/BackEndGradUpDown.png'
import GraphicSVG from './GraphicImages/GraphicSVG.png'
import BackEndSky from './GraphicImages/BackEndSky.png'

import cizma1 from './RasterImages/cizma1.jpg'
import cizma2 from './RasterImages/cizma2.jpg'
import cizma4 from './RasterImages/cizma4.jpg'
import cizma5 from './RasterImages/cizma5.jpg'
import cizma6 from './RasterImages/cizma6.jpg'
import cizma7 from './RasterImages/cizma7.jpg'
import cizma8 from './RasterImages/cizma8.jpg'
import cizma9 from './RasterImages/cizma9.jpg'
import cizma10 from './RasterImages/cizma10.jpg'
import cizma11 from './RasterImages/cizma11.jpg'
import cizma12 from './RasterImages/cizma12.jpg'
import cizma13 from './RasterImages/cizma13.jpg'
import cizma14 from './RasterImages/cizma14.jpg'


export const SliderDataGraphic = [
    {
        id: 1,
        rowid: 11,
        image:
        CarIcons
    },
    {
        id: 2,
        rowid: 22,
        image:
        InfoIcons
    },
    {
        id: 3,
        rowid: 33,
        image:
        BackEndGradUpDown
    },
    {
        id: 4,
        rowid: 44,
        image:
        GraphicSVG
    },
    {
        id: 5,
        rowid: 55,
        image:
        BackEndSky
    },
    
]


export const SliderRasterGraphic = [
    
    {
       id: 1,
        image:
        cizma1 
    },
    {
        id: 2,
        image:
        cizma2
    },
    {
        id: 4,
        image:
        cizma4
    },
    {
        id: 5,
        image:
        cizma5
    },
    {
        id: 6,
        image:
        cizma6
    },
    {
        id: 7,
         image:
         cizma7 
     },
     {
        id: 8,
         image:
         cizma8 
     },
     {
        id: 9,
         image:
         cizma9
     },
     {
        id: 10,
         image:
         cizma10
     },
     {
        id: 11,
         image:
         cizma11 
     },
     {
        id: 12,
         image:
         cizma12
     },
     {
        id: 13,
         image:
         cizma13
     },
     {
        id: 14,
         image:
         cizma14
     },
    
]











