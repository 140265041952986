import React from "react";
import { motion } from "framer-motion";

export function CardAndroidApp({ title, FrontImg, description, techDescription, IconsArr, GitLink, PlayLink }) {

    return (
        <>
            <div className="project-card-title"> {title} </div>
            <div className="project-card-in" >
                <div className="images-on-card" >
                    <div className="img-container">
                    <a href={PlayLink} target="_blank" rel="noreferrer" >
                            <img className="project-img" src={FrontImg} alt="img" />
                        </a>
                    </div>
                    <div className="icons-on-card">
                        <IconsArr />
                    </div>
                </div>
                <div className="project-card-right">
                    <div className="project-card-text">
                        {description}<br />
                        <span>{techDescription}</span><br />
                    </div>

                    <div className="project-card-btns" >
                        {/* BUTTON GitHub */}
                        <button>
                            <a href={GitLink} target="_blank" rel="noreferrer" >
                                <svg
                                    viewBox="0 0 15 15"
                                    width='70px' height='50px'>
                                    <motion.path
                                        d="M 4.9035 13.9715 C -2.0505 11.4395 -0.5875 1.8565 7.4 2 C 15.7255 1.8565 16.128 12.171 9.9345 13.9885 C 9.5435 14.0055 10.7675 9.2635 8.4175 8.9975 C 11.4 9 11.8865 6.1205 9.919 5.0075 C 10.027 4.6245 10.027 4.261 9.683 3.662 C 9.1625 3.809 8.7595 4.0255 8.396 4.6245 C 7.9 4.5 6.9 4.5 6.3925 4.605 C 6.1565 4.1825 5.813 3.8485 5.135 3.662 C 4.7615 4.1825 4.752 4.546 4.899 5.0075 C 3.187 5.779 3.8445 8.9845 6.3955 9.007 C 4.9 9.5 5.2333 10 5.0735 10.5215 C 4.4 10.5 4.253 9.691 3.669 9.416 C 2.9 9 2.4 9 1.9 9 C 2.4 9 2.9 9.5 3.342 9.912 C 3.687 10.779 3.9 11 5.05 11.417 C 5.0565 12.051 5.2435 13.9715 4.9205 13.9715"
                                        fill="transparent"
                                        strokeWidth="0.9"
                                        stroke="gray"
                                        strokeLinecap="round"
                                        animate={{ pathLength: [0, 1] }}
                                        transition={{ delay: 0, duration: 4 }}
                                    />
                                </svg>
                            </a>
                        </button>
                        {/* BUTTON Link to google play store */}
                        <button>
                            <a href={PlayLink} target="_blank" rel="noreferrer" >
                                <svg
                                    viewBox="0 0 15 15"
                                    width='70px' height='50px'>
                                    <motion.path
                                        d="M 3 1.6 L 9 7.6 L 2.948 13.675 C 2.451 13.356 2.44 12.8 2.44 12.4 L 2.44 2.8 C 2.44 2.4 2.44 1.6 3 1.6 M 4.2 13.2 L 10.6 9.2 L 9 7.6 L 2.95 13.679 C 3.318 13.886 3.691 13.509 4.209 13.198 M 3 1.6 L 9 7.6 L 10.6 6 L 4.2 2 C 3.4 1.6 3.4 1.6 3 1.6 M 9 7.6 L 10.6 6 L 12.2 7.2 C 12.6 7.6 12.6 7.6 12.2 8 L 10.6 9.2 L 9 7.6"
                                        fill="transparent"
                                        strokeWidth="0.9"
                                        stroke="gray"
                                        strokeLinecap="round"
                                        animate={{ pathLength: [0, 1] }}
                                        transition={{ delay: 0, duration: 4 }}
                                    />
                                </svg>
                            </a>
                        </button>

                    </div>
                </div>
            </div >
        </>
    );
}



