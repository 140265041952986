import React from 'react'
// import '../../styles/style.css'
import '../../../../styles/style.css'
// import { NavBar } from '../../components/menu-bar-up/NavBar'
import { NavBar } from '../../../../components/menu-bar-up/NavBar'
import PimkGong from './PimkGong'

export const Spielchen = () => {
    return (
        <>
            <div className="body-container">
                <div className="item-0">
                    <span className='nav-bar'>
                        <NavBar />
                    </span>
                </div>
                <br /><br />
                <div className="pimkgonk-container">
                    <div> <span className="card">
                        <article>                            
                            <PimkGong />
                        </article>
                    </span>
                    </div>
                    <div>
                        <span className="card">
                            <article>
                                There is no need to panic! <br />
                                It's only a game... <br />
                                Use the arrows to prevent the ball from escaping <br />over the left edge.<br />
                                Remember, this ball is smart. <br />
                                Try to get 3 points! Good Luck! ;-)
                            </article>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}