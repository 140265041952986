import React from 'react'
// import MyCode from '../images/MyCode.png'
import GraphicSVG from '../images/GraphicSVG.png'
import { NavBar } from '../components/menu-bar-up/NavBar'
import { Lock } from './SVGicons/Lock'
import { OnOff } from './SVGicons/OnOff'
import { Cross } from './SVGicons/Cross'
import { LettersAnim } from '../views/HomeComponents/LettersAnim'
import { Circle } from './SVGicons/Circle'
import { CheckV } from './SVGicons/CheckV'
import { Clues } from './SVGicons/Clues'
import { Clock } from './SVGicons/Clock'
import { QrCode } from './SVGicons/QrCode'

export const SvgIcons = () => {

    return (
        <>
            <div className="body-container">
                <div className="item-0">
                    <span className='nav-bar'>
                        <NavBar />
                    </span>
                </div>

                <div className="item-1">
                <div className="images-on-card" >
                    <div className="thumb-main-img">                        
                        <img className="project-img" src={GraphicSVG} alt="img" />                        
                    </div>
                </div>
                    
                    
                    
                    
                    
                    {/* <span className="card-main">
                        <div className="thumb-main" style={{ backgroundImage: `url(${GraphicSVG})` }}></div>
                        <article>

                        </article>
                    </span> */}
                </div>
                {/* <div className="item-2">
                    <span className="card">
                        <div className="thumb" style={{ backgroundImage: `url(${MyCode})` }}></div>
                        <article>
                            Auf dieser Seite präsentiere ich von mir erstellte SVG-path icons
                            mit hinzugefügten Framer Motion-Animationen
                        </article>
                    </span>
                </div> */}
                {/* <div className="item-10">
                    <span className="card">
                        <div className="thumb" style={{ backgroundImage: `url(${MyCode})` }}></div>
                        <article>
                            Jedes Grafikelement auf alle meine Webseiten wird von mir erstellt.
                            Viele grafische Elemente erfinde ich selbst.
                            Alle Grafiken wurden von mir im Grafikprogramm neu gezeichnet.
                        </article>
                    </span>
                </div> */}

            </div>
            {/* <div className="body-container-javaapps svg"> */}
            <div className='home-center'>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <Clock />
                        </article>
                    </div>
                </div>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <OnOff />
                        </article>
                    </div>
                </div>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <Cross />
                        </article>
                    </div>
                </div>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <LettersAnim />
                        </article>
                    </div>
                </div>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <Circle />
                        </article>
                    </div>
                </div>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <CheckV />
                        </article>
                    </div>
                </div>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <Clues />
                        </article>
                    </div>
                </div>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <Lock />
                        </article>
                    </div>
                </div>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <QrCode />
                        </article>
                    </div>
                </div>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <QrCode />
                        </article>
                    </div>
                </div>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <QrCode />
                        </article>
                    </div>
                </div>
                <div className="home-card">
                    <div className="card">
                        <article>
                            <QrCode />
                        </article>
                    </div>
                </div>
            </div>
        </>


    )
}
