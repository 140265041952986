// Home Home with GRID with consecutive blocks (item-0 to 9)
// displayed responsively depending on the size of the user's device

import React from 'react'
import '../styles/style.css'
import { Link } from "react-router-dom";
// import MyCode from '../images/MyCode.png'
import MyCodeColor from '../images/MyCodeColor.png'
import AndroidApps from '../images/AndroidApps.png'
import Certificate from '../images/Certificate.png'
import FrontEndJSReact from '../images/FrontEndJSReact.png'
import FrontEndHTMLCSSFrame from '../images/FrontEndHTMLCSSFrame.png'
import FrontSystems from '../images/FrontSystems.png'
import GraphicSVG from '../images/GraphicSVG.png'
import FormDesign from '../images/FormDesign.png'
import { NavBar } from '../components/menu-bar-up/NavBar'
import { LettersAnim } from './HomeComponents/LettersAnim'
// import { Cookies } from './HomeComponents/Cookies'
import { HomeHeader } from './HomeHeader';

export const Home = () => {
    // Home home page with more items tabs. In each tab, a description of the skills or other things

    return (
        <>
            <NavBar />
            <div className='body'>

                <div className='item-header'>
                    <HomeHeader />
                </div>

                <div className='home-center'>
                    <div className="home-card">

                        <span className="card">
                            <Link to='/projects'>
                                <div className="thumb" style={{ backgroundImage: `url(${MyCodeColor})` }}></div>
                            </Link>
                            <article>
                                <Link to='/projects'>
                                    <h1>My Projects </h1>
                                </Link>
                                <p>Android Apps, Web Apps in React, Jquery, Vue, Html, SCSS, </p>
                                <span>Frontend, Backend, Middleware</span>
                            </article>
                        </span>

                    </div>
                    <div className="home-card">
                        <span className="card">
                            <div className="thumb" style={{ backgroundImage: `url(${FrontEndJSReact})` }}></div>
                            <article>
                                <h1>Frontend: </h1>
                                <p>JavaScript, React Hooks, Jquery, Vue, AndroidStudio</p>
                                <span>Frontennd</span>
                            </article>
                        </span>
                    </div>
                    <div className="home-card">
                        <span className="card">
                            <div className="thumb" style={{ backgroundImage: `url(${FrontEndHTMLCSSFrame})` }}></div>
                            <article>
                                <h1>Website Development: </h1>
                                <p>Html, CSS, SCSS, Framer - Motion</p>
                                <span>FRONTEND</span>
                            </article>
                        </span>
                    </div>
                    <div className="home-card">
                        <span className="card">
                            <div className="thumb" style={{ backgroundImage: `url(${FrontSystems})` }}></div>
                            <article>
                                <h1>Server Platforms und Systeme Konfiguration</h1>
                                <p>AWS Amazon, GoogleCloud, Apache2, Ubuntu 20.04, SSL</p>
                                <span>SERVERS SYSTEME</span>
                            </article>
                        </span>
                    </div>
                    <div className="home-card">
                        <span className="card">
                            <div className="thumb" style={{ backgroundImage: `url(${FormDesign})` }}></div>
                            <article>
                                <h1>UI-UX-Formulare für jeden Benutzer</h1>
                                <p>Bootstrap, React-Hooks, Jquery, Vue</p>
                                <span>UI - UX</span>
                            </article>
                        </span>
                    </div>
                    <div className="home-card">
                        <span className="card">
                            <div className="thumb" style={{ backgroundImage: `url(${GraphicSVG})` }}></div>
                            <article>
                                <h1>Vektor- oder Rastergrafiken</h1>
                                <p></p>
                                <LettersAnim />
                                <span>GRAPHS</span>
                            </article>
                        </span>
                    </div>
                    <div className="home-card">
                        <span className="card">
                            <div className="thumb" style={{ backgroundImage: `url(${AndroidApps})` }}></div>
                            <article>
                                <h1>Android Apps</h1>
                                <p>AndroidStudio, Kotlin, XML, FireBase, SQLite</p>
                                <span>AndroidApps</span>
                            </article>
                        </span>
                    </div>
                    <div className="home-card">
                        <span className="card">
                            <div className="thumb" style={{ backgroundImage: `url(${Certificate})` }}></div>
                            <article>
                                <h1>Certified Foundations Associate</h1>
                                <p>2021 - Zertifikat 1Z0-811 JAVA ORACLE Java (Certified Foundations Associate) erhalten.
                                </p>
                                <span>ollo.link</span>
                            </article>
                        </span>
                    </div>
                </div>
                {/* END HOME CENTER */}


                {/* HOEM FOOTER */}
                <div className="home-footer">
                <div className="home-footer-text">
          Ich schreibe Anwendungen in AndroidStudio, habe aber auch Erfahrung in
          Java, HTML, CSS, JS, FrameWorks React, JQuery, Vue. Wenn Du eine coole
          Idee für eine Android-App hast, teilen sie bitte mit. Wenn mich die
          Idee interessiert, setze ich ein solches Projekt gerne um.
        </div>
                    <span className="card">
                        <article>
                            <span> www.ollo.link - - adress: Eschborn - - email: ollo7776@gmail.com - - telefon: 015207073112 </span>
                        </article>
                    </span>
                </div>
                {/* END HOME FOOTER */}
            </div >
            {/* END BODY */}

            {/* COOKIES COOKIES COOKIES COOKIES */}
            {/* <Cookies /> */}
            {/* END COOKIES */}
        </>
    )
}

export default Home
