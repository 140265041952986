import Img1 from '../../ProjectsImgs/NewApps/AppComingSoon.png'
import Img2 from '../../ProjectsImgs/NewApps/AndroidAppNew.png'

import Icon1 from '../../ProjectsImgs/icons_imgs/gplay-icon.png'
import Icon2 from '../../ProjectsImgs/icons_imgs/xml_icon.png'

import FrontImg from '../../ProjectsImgs/NewApps/AndroidAppNew.png'

export const EBListImagesList = [
    {
        id: 1,
        image:
            Img1
    },    
    {
        id: 2,
        image:
            Img2
    },    
]

export const EBListTitle = 'Evaluation Business List - Android Version (in Vorbereitung)'
export const EBListFrontImg = FrontImg
export const EBListDescription = 'Ein Programm zur Unterstützung von Entscheidungen im Unternehmensbetrieb. Mitarbeiter und Management reichen Ideen und Probleme ein, die im operativen Betrieb des Unternehmens entwickelt oder adäquat gelöst werden sollen.'
export const EBListTechDescription = 'Android Studio - Kotlin, XML, Firebase Database'


const iconsList = [
    {
        id: 1,
        icon: Icon1
    },      
    {
        id: 2,
        icon: Icon2
    },
]

export const EBListIconsComponent=()=> {
    return (
        <div className='icons-on-card'>
            {iconsList.map((icon) => (
                <img className="project-icon" key={icon.id} src={icon.icon} alt={icon.id} />
            ))}
        </div>
    );
}
