import React from 'react'
import '../../styles/style.css'
import CookiesBanner from './CookiesBanner'

export const Cookies = () => {
    return (
        <>
            <div className="cookies-banner">
                <article>
                    <CookiesBanner />
                </article>

            </div>
        </>
    )
}







