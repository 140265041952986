import React from "react";
import "../styles/style.css";
import { Link } from "react-router-dom";

export const HomeHeader = () => {
  return (
    <>
      <div className="home-header">
        <svg className="home-header-background-shape" viewBox="15 -15 80 80">
          <path d="M 41 38 L 40 39 L -88 94 L -10 61 L 41 39 L 152 38 L 234 37 L 42 38 L 41 -10 L 41 -46 L 41 38" />
        </svg>
        <svg className="home-header-background-shape" viewBox="15 -15 80 80">
          <path d="M 21 50 L 42 41 L 241 39 L 145 66 L 21 51" />
        </svg>
        {/* <div className='home-header-title-1'>Hi, I'm</div> */}
        {/* <div className="home-header-title-Aleks">Aleks</div> */}
        <div className="home-header-title-Code">Code</div>
        <div className="home-header-title-Android">Android</div>
        <div className="home-header-title-Kotlin">Kotlin</div>
        <div className="home-header-title-JetPack">JetPack</div>
        <div className="home-header-title-Compose">Compose</div>
        <div className="home-header-title-React">React</div>
        <div className="home-header-title-FramerMotion">FramerMotion</div>
        <div className="home-header-title-CSS">CSS</div>
        <div className="home-header-title-HTML">HTML</div>
        <div className="home-header-title-VUE">VUE</div>
        <div className="home-header-title-JQuery">JQuery</div>

        

        {/* <div className='home-header-title-3'>Hi, I'm <br/> &lt;code is&gt;<br/> &lt;my passion!&gt;</div> */}
        <div className="home-header-title-passion">
          <br></br>&lt;This is my passion!&gt;
        </div>

        {/* <div className='home-header-shape-square' /> */}
        <div className="link-on-header">          
        </div>
        <div className="home-header-text">
        {/* <Link to="/projects">
            <h1>My Projects </h1>
          </Link> */}
        </div>
      </div>
    </>
  );
};
